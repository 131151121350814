import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import TickerLink from './helpers/TickerLink';
import { useSearchParams } from 'react-router-dom'

export default function Gapper({getStockData, setTicker, url}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [gappersMap, setGappers] = useState(new Map());

    useEffect(() => {
        const eventSource = new EventSource(url)
        eventSource.onmessage =  event => {
              let message = JSON.parse(event.data);
              setGappers(map => new Map(map.set(message.ticker, message)));
        }
    }, []);

    const handleTickerDetails = async (e) => {
//        e.preventDefault();
        var ticker = e.target.innerText.toUpperCase();
        setSearchParams({search: ticker});
    }

    const formatChange = (change) => (change > 0 ? `+${change}` : change);

    if(gappersMap.size === 0){
        return (<p> - </p>);
    }

    return (
        <>
        <Table striped bordered hover variant="dark" size="sm" style={{ width: '75%', textAlign: 'center', margin: '0 auto'  }}>
          <thead>
            <tr>
              <th width="150">Ticker</th>
              <th width="250">🔅Pre-market</th>
              <th>Last Closing</th>
            </tr>
          </thead>
          <tbody>
            {[...gappersMap.values()].map(({ ticker, exchange, currency, preMarketPrice, preMarketPriceChange, preMarketPriceChangeInPercentage, lastPrice, lastPriceChange, lastPriceChangeInPercentage, lastVolume}, id) => (
                <tr key={ticker}>
                    <td>
                        <small className="text-muted">
                            <a href={'/?search='+ticker} target="_blank" rel="noreferrer"
                            style={{ textDecoration: 'none'}}>{ticker}</a>
                        </small>
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <b>{preMarketPrice}</b> <span style={{ fontSize: '0.66rem' }}>{currency}</span>
                        <small style={{ color: preMarketPriceChangeInPercentage > 0 ? 'green' : 'red' }}>
                             &nbsp;&nbsp;<b>{formatChange(preMarketPriceChange)}</b>&nbsp;
                        </small>
                        <Badge
                          bg={preMarketPriceChangeInPercentage > 0 ? "success" : "danger"}
                          className={Math.abs(preMarketPriceChangeInPercentage) < 5 ? "fw-bold text-dark" : ""}
                        >
                         <small>{formatChange(preMarketPriceChangeInPercentage)}%</small>
                        </Badge>
                    </td>
                    <td>
                        <b>{lastPrice}</b> <span style={{ fontSize: '0.66rem' }}>{currency}</span>
                        <small style={{ color: lastPriceChangeInPercentage > 0 ? 'green' : 'red' }}>
                             &nbsp;&nbsp;<b>{formatChange(lastPriceChange)}&nbsp;{formatChange(lastPriceChangeInPercentage)}%</b>
                        </small>
                    </td>
                </tr>
            ))}
          </tbody>
        </Table>
        </>
    );
}