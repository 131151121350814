import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ReactECharts from 'echarts-for-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Chart from './Chart';
import * as Icon from 'react-bootstrap-icons';
import Helmet from "react-helmet";
import Loading from './helpers/Loading'
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
import OptionsChainChart from './OptionsChainChart';
import SuperInvestorChart from './SuperInvestorChart';
import SuperInvestorTable from './SuperInvestorTable';
import ExportFullPagePDF from './ExportFullPagePDF';

//https://github.com/hustcc/echarts-for-react
//https://echarts.apache.org/examples/en/index.html#chart-type-parallel
export default function StockData({stockData, loadingStockDataSpinner}) {
    const [showMore, setShowMore] = useState(false);
    let goodColor = '#14A44D';
    let badColor = '#ffb74d';
    let veryBadColor = '#FF3C3C';
    let defaultBarColor = '#5999f6';

    if (Object.keys(stockData).length === 0){
        return (
            <div className="text-center">
                <Loading visibility={loadingStockDataSpinner} />
            </div>
        );
    }

    const handleShowMore = async (e) => {
        e.preventDefault();
        setShowMore(!showMore);
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const convertToNumber = (value) => {
      if (typeof value !== 'string') return value;
      if (!isNaN(value)) return parseFloat(value);

      const multipliers = {
        k: 1000,
        m: 1000000,
        b: 1000000000,
      };

      const match = value.match(/^(\d*\.?\d+)([kmb]?)$/i);
      if (!match) return 0;

      const num = parseFloat(match[1]);
      const suffix = match[2].toLowerCase();
      return suffix ? num * multipliers[suffix] : num;
    };

    function prepareRows(data) {
        const tableRows = [];
        for (let i = 0; i < data.length; i = i + 2) {
              tableRows.push(
                <tr key={data[i] + "-" + i}>
                    <td className="col-md-3">
                        <small className="text-muted">{data[i][0]}</small>
                    </td>
                    <td className="col-md-3">
                        <small><b>{formatStockData(data[i][0], data[i][1])}</b></small>
                    </td>
                    <td className="col-md-3">
                       <small className="text-muted"> {extractAtIndex(data[i + 1], 0)}</small>
                    </td>
                    <td className="col-md-3">
                        <small><b>{formatStockData(extractAtIndex(data[i + 1], 0), extractAtIndex(data[i + 1], 1))}</b></small>
                    </td>
                </tr>
              );
        }
        return tableRows;
    }

    function formatStockData(key, value){
        var badgeClass = "none"
        if(value.trim() === '-'){
            badgeClass = "danger";
        } else if(key.includes("Debt/Eq")){
            if(parseFloat(value) < 0.1){
                badgeClass = "success";
            } else if(parseFloat(value) > 0.5) {
                badgeClass = "danger";
            }
        } else if(key.includes('Insider Own') && parseFloat(value) >= 30){
             badgeClass = "success";
        } else if(key.includes("Insider Trans") || key.includes("Inst Trans") || key.includes("Institutional Transactions")){
            if(parseFloat(value) < -10){
                badgeClass = "danger";
            } else if (parseFloat(value) > 10){
                badgeClass = "success";
            }
        } else if(key.includes("ROI")) {
              if(parseFloat(value) <= -10){
                  badgeClass = "danger";
              } else if (parseFloat(value) > 20){
                  badgeClass = "success";
              }
        } else if(key.includes("Short Float") && parseFloat(value) > 20){
           badgeClass = "danger";
        } else if(key.includes("Dividend") && !key.includes("Date") && parseFloat(value) > 5){
            badgeClass = "success";
        } else if(key.includes("Operating Margin") || key.includes("Oper. Margin")){
             if(parseFloat(value) > 25){
                 badgeClass = "success";
             } else if(parseFloat(value) < 0) {
                 badgeClass = "danger";
             }
         } else if(key.includes("Upside")){
              if(parseFloat(value) > 0){
                  badgeClass = "success";
              } else {
                  badgeClass = "danger";
              }
         } else if(key.includes("EPS") && parseFloat(value) < 0){
                    badgeClass = "danger";
         }

        return <Badge bg={badgeClass}>{value}</Badge>;
    }

    function extractAtIndex(data, index){
        if(data == null){
            return "";
        }
        return data[index];
    }

    let targetPriceOption = {
      backgroundColor: 'transparent',
      title: {
        text: 'Price Target',
        subtext: 'The analysts offering one year price forecasts to have a max estimate of '+stockData.details["Target Price"]+' $'
      },
       xAxis: {
         type: 'category',
         data: ['Fair Value', 'DCF 5y', 'Price', 'Target Price']
       },
       yAxis: {
         type: 'value',
         splitLine: {
            lineStyle: {
              type: 'dashed'
            }
         }
       },
       series: [
         {
           data: [
            {
              value: stockData.details["Fair Value"],
              itemStyle: {
                color: generatePriceVsFairPriceColor(parseFloat(stockData.details.Price), parseFloat(stockData.details["Fair Value"]))
              }
            },
            {
              value: stockData.details["DCF (Growth 5y)"],
              itemStyle: {
                color: generatePriceVsFairPriceColor(parseFloat(stockData.details.Price), parseFloat(stockData.details["DCF (Growth 5y)"]))
              }
            },
             {
               value: stockData.details.Price,
               itemStyle: {
                 color: generatePriceVsTargetColor(parseFloat(stockData.details.Price), parseFloat(stockData.details["Target Price"]))
               }
             },
             stockData.details["Target Price"]
           ],
           type: 'bar',
           barWidth: '50%',
           showBackground: true,
           backgroundStyle: {
             color: 'rgba(180, 180, 180, 0.2)'
           },
           label: {
              show: true,
              position: 'inside'
           }
         }
       ]
     };

  let analystRecommendationOption = {
     backgroundColor: 'transparent',
     series: [
       {
         min: 5,
         max: 1,
         splitNumber: 16,
         startAngle: 180,
         endAngle: 0,
         type: 'gauge',
         progress: {
           show: true,
           width: 18
         },
         axisLine: {
           lineStyle: {
             width: 18,
             color: [
                 [1, '#464646']
             ]
           }
         },
         axisTick: {
           show: false
         },
         splitLine: {
           show: false,
           length: 15,
           lineStyle: {
             width: 2,
             color: '#999'
           }
         },
         axisLabel: {
           color: '#5d606b',
           fontSize: 20,
           distance: -60,
           rotate: 'tangential',
           formatter: function (value) {
             if (value === 4.75) {
               return 'Strong\nsell';
             } else if (value === 4) {
               return 'Sell';
             } else if (value === 3) {
               return 'Neutral';
             } else if (value === 2) {
               return 'Buy';
             } else if (value === 1.25) {
               return 'Strong\nbuy';
             }
             return '';
           }
         },
         itemStyle: {
             color: generateAnalystRecommendationColor(stockData.details.Recom),
             shadowColor: 'rgba(0,138,255,0.45)',
             shadowBlur: 10,
             shadowOffsetX: 3,
             shadowOffsetY: 3
         },
         pointer: {
             length: '70%',
             width: 5,
             offsetCenter: [0, '-10%']
         },
         anchor: {
           show: false,
           showAbove: true,
           size: 20,
           itemStyle: {
             borderWidth: 5
           }
         },
         title: {
           offsetCenter: [0, '15%'],
           fontSize: 25,
           fontWeight: 'bolder',
           color: generateAnalystRecommendationColor(stockData.details.Recom),
         },
         detail: {
           show: false
         },
         data: [
           {
             value: stockData.details.Recom,
             name: generateAnalystRecommendationTitle(stockData.details.Recom)
           }
         ]
       }
     ]
   };

     let peOption = {
        backgroundColor: 'transparent',
        title: {
            text: 'Price-to-Earnings',
            subtext: 'P/B vs P/E vs Forward P/E vs Price-to-Earnings-to-Growth'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 40
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
            {
                value: stockData.details["P/B"],
                name: 'P/B Ratio',
                title: {
                  offsetCenter: ['0%', '-50%']
                },
                detail: {
                  valueAnimation: false,
                  offsetCenter: ['0%', '-35%']
                },
                itemStyle: {
                  color: pbColor(parseFloat(stockData.details["P/B"]))
                }
              },
              {
                value: stockData.details["P/E"],
                name: 'P/E',
                title: {
                  offsetCenter: ['-30%', '-5%']
                },
                detail: {
                  valueAnimation: false,
                  offsetCenter: ['-30%', '10%']
                },
                itemStyle: {
                  color: peColor(parseFloat(stockData.details["P/E"]))
                }
              },
              {
                  value: stockData.details["Forward P/E"],
                  name: 'Forward P/E',
                  title: {
                    offsetCenter: ['30%', '-5%']
                  },
                  detail: {
                    valueAnimation: false,
                    offsetCenter: ['30%', '10%']
                  },
                  itemStyle: {
                    color: peColor(parseFloat(stockData.details["Forward P/E"]))
                  }
              },
              {
                  value: stockData.details["PEG"],
                  name: 'PEG',
                  title: {
                    offsetCenter: ['0%', '35%']
                  },
                  detail: {
                    valueAnimation: false,
                    offsetCenter: ['0%', '50%']
                  },
                  itemStyle: {
                    color: pegColor(parseFloat(stockData.details["PEG"]))
                  }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1
            }
          }
        ]
    };

    let epsOption = {
      backgroundColor: 'transparent',
      title: {
        text: 'Earnings Per Share',
        subtext: 'EPS growth(%) from last 5 years to the next 5 years growth estimate'
      },
       xAxis: {
         type: 'category',
         data: ['EPS past 5Y', 'EPS this Y', 'EPS next Y', 'EPS next 5Y']
       },
       yAxis: {
         type: 'value',
         splitLine: {
            lineStyle: {
              type: 'dashed'
            }
         }
       },
       series: [
         {
           data: [
             {
                value: parseFloat(stockData.details["EPS past 5Y"]),
                itemStyle: {
                  color: epsColor(parseFloat(stockData.details["EPS past 5Y"]))
                }
             },
             {
                value: parseFloat(stockData.details["EPS this Y"]),
                itemStyle: {
                  color: epsColor(parseFloat(stockData.details["EPS this Y"]))
                }
             },
             {
                value: parseFloat(stockData.details["EPS next Y"]),
                itemStyle: {
                  color: epsColor(parseFloat(stockData.details["EPS next Y"]))
                }
             },
             {
                value: parseFloat(stockData.details["EPS next 5Y"]),
                itemStyle: {
                  color: epsColor(parseFloat(stockData.details["EPS next 5Y"]))
                }
             }
           ],
           type: 'bar',
           barWidth: '75%',
           showBackground: true,
           backgroundStyle: {
             color: 'rgba(180, 180, 180, 0.2)'
           },
           label: {
              show: true,
              position: 'inside',
              formatter: '{c}%'
           }
         }
       ]
     };

     let balanceSheetOption = {
       backgroundColor: 'transparent',
       title: {
         text: 'Balance sheet',
         subtext: 'Values in USD, period: '+stockData.balanceSheet.periodEndDate
       },
        xAxis: {
          type: 'category',
          data: ['Debt', 'Long\nTerm\nDebt', 'Total\nCurrent\nLiabilities', 'Total\nLiabilities',
          'Cash', 'Total\nCurrent\nAssets', 'Total\nAssets']
        },
        yAxis: {
          type: 'value',
          splitLine: {
             lineStyle: {
               type: 'dashed'
             }
          },
          axisLabel: {
            formatter: (value) => {
              if (value >= 1000000000) return `${value / 1000000000}B`;
              if (value >= 1000000) return `${value / 1000000}M`;
              if (value >= 1000) return `${value / 1000}K`;
              return value;
            },
          }
        },
        series: [
          {
            data: [
              convertToNumber(stockData.balanceSheet.shortTermDebt),
              convertToNumber(stockData.balanceSheet.longTermDebt),
              convertToNumber(stockData.balanceSheet.totalCurrentLiabilities),
              convertToNumber(stockData.balanceSheet.totalLiabilities),
             {
               value: convertToNumber(stockData.balanceSheet.cash),
               itemStyle: {
                 color: colorComparing(convertToNumber(stockData.balanceSheet.cash), convertToNumber(stockData.balanceSheet.shortTermDebt))
               }
             },
             convertToNumber(stockData.balanceSheet.totalCurrentAssets),
             {
                value: convertToNumber(stockData.balanceSheet.totalAssets),
                itemStyle: {
                  color: colorComparing(convertToNumber(stockData.balanceSheet.totalAssets), convertToNumber(stockData.balanceSheet.totalLiabilities))
                }
             }
            ],
            type: 'bar',
            barGap: 0,
            showBackground: true,
            backgroundStyle: {
                color: 'transparent'
            },
            label: {
               show: true,
               position: 'top',
               formatter: function (params) {
                  const data = params.data;
                  const value = data && typeof data === 'object' && 'value' in data ? data.value : data;
                  if (value >= 1000000000) return `${value / 1000000000}B`;
                  if (value >= 1000000) return `${value / 1000000}M`;
                  if (value >= 1000) return `${value / 1000}K`;
                  return value;
              },
             textStyle: {
                   fontWeight: 'bold',
             }
            }
          }
        ]
      };


    function prepareSuperInvestorActivities(requestedActivity, colour) {
        var activities = [];
        stockData.superInvestorOwnership.recentActivities.forEach((activityObj) => {
            if(activityObj.activity.includes(requestedActivity)){
                var item = {}
                item ["name"] = activityObj.portfolioManager+"\n"+activityObj.activity;
                item ["value"] = 1;

                var itemStyle = {}
                itemStyle["color"] = colour
                item["itemStyle"] = itemStyle

                activities.push(item)
            }
        });
        return activities;
    }

    function generateAnalystRecommendationTitle(value){
        if(value <= 1.5){
            return "Strong Buy"
        } else if(value > 1.5 && value <= 2.5) {
            return "Buy"
        } else if(value > 2.5 && value <= 3.5) {
            return "Neutral"
        } else if(value > 3.5 && value <= 4.5) {
             return "Sell"
        } else if(value > 4.5) {
            return "Strong Sell"
        }

        return "";
    }

    function generateAnalystRecommendationColor(value){
        const title = generateAnalystRecommendationTitle(value);
        if(title.endsWith('Buy')){
            return goodColor;
        }
        if(title.endsWith('Sell')){
            return badColor;
        }
        return '#FFF';
    }

    function generatePriceVsTargetColor(price, targetPrice){
        if(price >= targetPrice){
            return "#ffb74d";
        }

        return '#14A44D';
    }

    function generatePriceVsFairPriceColor(price, fairPrice){
        if(price > fairPrice){
            return veryBadColor;
        }

        return '#14A44D';
    }

    function colorComparing(firstValue, secondValue){
        if(firstValue < secondValue){
            return veryBadColor;
        }

        return '#14A44D';
    }

    function epsColor(eps){
        if(eps < 0){
            return veryBadColor;
        } else if(eps > 25){
            return goodColor;
        }

        return defaultBarColor;
    }

    function peColor(pe){
        if(pe < 15){
            return goodColor;
        } else if(pe > 50){
            return veryBadColor;
        }

        return "#FFF";
    }
    function pegColor(peg){
        if(peg < 1) {
            return goodColor;
        } else if(peg > 2) {
            return veryBadColor;
        }

        return "#FFF";
    }
    function pbColor(pb){
        if(pb < 1) {
            return goodColor;
        } else if(pb > 5) {
            return veryBadColor;
        }

        return "#FFF";
    }

    function formatRemainingDays(remainingDays2Earning){
        if(remainingDays2Earning < 0 || remainingDays2Earning > 50){
            return <span></span>;
        }
        let earningDate = stockData.details["Earnings"].replace("AMC", "After Market Close").replace("BMO", "Before Market Open");
        var badgeBGColor = "dark";
        if(remainingDays2Earning === 0 || remainingDays2Earning === 1){
            return <span><Badge bg="danger" text="light">{remainingDays2Earning === 0? "Today":"Tomorrow"}</Badge> is company Earning day! {earningDate}</span>;
        } else if(remainingDays2Earning <= 4){
            badgeBGColor = "danger"
        } else if(remainingDays2Earning < 10){
            badgeBGColor = "warning"
        }

        return <span>
        Next earning date is in <Badge bg={badgeBGColor} text="light">{remainingDays2Earning}</Badge> days! {earningDate}!
        </span>;
    }

    function formatSuperInvestorOwnershipData(superInvestorOwnership){
        if(superInvestorOwnership.count === 0){
            return <span><Icon.ExclamationCircleFill /> No prominent(super) investor currently includes this stock in their portfolio.</span>;
        }
        return <span><Icon.CheckCircleFill /> <Badge bg="success">{superInvestorOwnership.count}</Badge> prominent(super) investor out of 80 includes this stock in their portfolio.
        Current Rank is <b>{superInvestorOwnership.rank}</b></span>;
    }

    return (
        <>
            <Helmet>
                <title>{stockData.title}</title>
                <meta name="description" content={stockData.returnOnInvestment} />
            </Helmet>
            <Container>
              <Row>
                <p>&nbsp;</p>
                <Col md={2}>
                    <Image src={stockData.logo != null? stockData.logo:"https://sigmatrader.net/logo512.png"} roundedCircle style={{height: '165px'}} />

                </Col>
                <Col className="text-left" style={{marginLeft: '20px'}}>
                    <Loading visibility={loadingStockDataSpinner} />
                    <h2 className="text-muted">{stockData.title} <ExportFullPagePDF fileName={stockData.title} /></h2>
                    <Accordion flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header style={{marginLeft: '-20px'}}>{stockData.sector}</Accordion.Header>
                        <Accordion.Body style={{marginLeft: '-30px'}}>
                          <small className="text-muted">{stockData.desc}</small>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h6 className="text-muted">
                        Peers: {stockData.peers.map((peer, index) => (

                           <span key={index}>
                               <a href={`?search=${peer}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>{peer}</a>
                               {index < stockData.peers.length - 1 && <span>&nbsp;|&nbsp;</span>}
                            </span>
                         ))}
                    </h6>
                    <h6 className="text-muted">{stockData.returnOnInvestment}</h6>
                    <h6 className="text-muted">{formatSuperInvestorOwnershipData(stockData.superInvestorOwnership)}</h6>
                    <h6 className="text-muted">
                        Earnings Date: <b>{stockData.details["Earnings"]}</b> &nbsp; vs &nbsp; Ex-Dividend Date: <b>{stockData.details["Dividend Ex-Date"]}</b>
                    </h6>
                    <h6 className="text-muted">{formatRemainingDays(stockData.remainingDays2Earning)}</h6>
                    <p>&nbsp;</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                    <ReactECharts
                        theme='dark'
                        option={targetPriceOption}
                        style={{height: '300px', width: '450px'}} />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                    <p>&nbsp;</p>
                    <ReactECharts
                        theme='dark'
                        option={analystRecommendationOption}
                        style={{height: '333px', width: '400px'}} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                    <ReactECharts
                        theme='dark'
                        option={peOption}
                        style={{height: '400px', width: '450px'}} />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                    <ReactECharts
                        theme='dark'
                        option={epsOption}
                        style={{height: '400px', width: '450px'}} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                    <ReactECharts
                        theme='dark'
                        option={balanceSheetOption}
                        style={{height: '400px', width: '750px'}} />
                </Col>
              </Row>
              {stockData.superInvestorOwnership.count > 0 &&
              (
                  <Row>
                      <Col md={8}>
                          <SuperInvestorTable data={stockData.superInvestorOwnership} />
                      </Col>
                      <Col md={4}>
                            <SuperInvestorChart data={stockData.superInvestorOwnership} />
                      </Col>
                  </Row>
              )}
            </Container>

            <p>&nbsp;</p>
            <div align="left" style={{width: '70%', display: 'inline-block'}}>
                View more details on&nbsp;
                <a href={"https://finviz.com/quote.ashx?t="+stockData.ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    Finviz
                </a>
                ,&nbsp;&nbsp;
                <a href={"https://valueinvesting.io/"+stockData.ticker+"/valuation/intrinsic-value"} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    ValueInvesting
                </a>
                ,&nbsp;&nbsp;
                <a href={"https://www.dataroma.com/m/stock.php?sym="+stockData.ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    DATAROMA
                </a>
                ,&nbsp;&nbsp;
                <a href={"https://finance.yahoo.com/quote/"+stockData.ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    Yahoo Finance
                </a>
                &nbsp;&nbsp;or&nbsp;&nbsp;
                <a href={"https://www.tradingview.com/symbols/"+stockData.ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    TradingView
                </a>

            </div>
            <div align="right" style={{width: '30%', display: 'inline-block', marginBottom: '10px'}}>
                <Button variant="btn btn-outline-secondary" size="sm" onClick={handleShowMore}><Icon.ThreeDotsVertical /> {showMore? "Close":"Show More"}</Button>
                &nbsp;&nbsp;
                <Button variant="btn btn-primary" size="sm"onClick={() => openInNewTab('/compare-stocks?tickers='+stockData.ticker)}><Icon.PlusSlashMinus /> Compare</Button>
            </div>
            <Table striped bordered hover size="sm" variant="dark" responsive="md">
              <tbody>
                {prepareRows(Object.entries(showMore? stockData.details:stockData.hotData))}
              </tbody>
            </Table>

            <Chart ticker={stockData.ticker} />
            <p>&nbsp;</p>

            <Table striped bordered hover size="sm" variant="dark" responsive="md">
                <thead>
                    <tr>
                        <th>Insider Name</th>
                        <th>Relationship</th>
                        <th>Transaction</th>
                        <th>Date</th>
                        <th>Cost</th>
                        <th>Shares</th>
                        <th>Value ($)</th>
                        <th>Total Shares</th>
                    </tr>
                </thead>
                <tbody>
                    {[...stockData.insiderTradings].map(({ name, relationship, transaction, date, cost, shares, value, totalShares}, id) => (
                        <tr key={name+"-"+id}>
                            <td>{name}</td>
                            <td>{relationship}</td>
                            <td><Badge bg={transaction.toLowerCase().includes("sale")? "danger":"success"} text="light">{transaction}</Badge></td>
                            <td>{date}</td>
                            <td>{cost}</td>
                            <td>{shares}</td>
                            <td>{value}</td>
                            <td>{totalShares}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <p>&nbsp;</p>
            <OptionsChainChart ticker={stockData.ticker} />
            </>
    );
}