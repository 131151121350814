import React, { useState, useEffect } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Loading from './helpers/Loading'
import Table from 'react-bootstrap/Table';
import { useSearchParams } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-bootstrap-icons';

export default function Compare() {
    const [tickersParams, setTickersParams] = useSearchParams({tickers: ''})

    const [loadingStockDataSpinner, setLoadingStockDataSpinner] = useState("visually-hidden")
    const filterBy = () => true;
    const [searchQuery, setSearchQuery] = useState(prepareOptions());
    const [options, setOptions] = useState(prepareOptions());
    const [isLoading, setIsLoading] = useState(false);

    const [tickers, setTickers] = useState([])
    const [stocksData, setStocksData] = useState({})

    function prepareOptions(){
        const tickers =  tickersParams.get('tickers').trim().toUpperCase();
        if(tickers === ""){
            return [];
        }

        const tickersForOptions = []
        for(const t of tickers.split(",")){
            tickersForOptions.push({ticker: t, name: t})
        }

        return tickersForOptions;
    }

    useEffect(() => {
        const tickers =  tickersParams.get('tickers');
        if(tickers === ""){
            return;
        }

        handleCompare()
    }, []);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleSearch = (query: string) => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_SEARCH_TICKER_URL}&query=${query}`)
          .then((resp) => resp.json())
          .then(data => {
            setOptions(data);
            setIsLoading(false);
          });
    };

    const handleCompare = () => {
        if(searchQuery.length === 0){
            return;
        }

        var tickersArr = searchQuery.map((item) => { return item.ticker });
        setTickers(tickersArr)
        setTickersParams({tickers: tickersArr.join(",")});
        setStocksData({})
        getStocksData(tickersArr)
    }

    const getStocksData = async (tickers) => {
        try {
          setLoadingStockDataSpinner("visually-visible")
          const response = await fetch(`${process.env.REACT_APP_STOCK_TO_COMPARE_URL.replace("{tickers}", tickers)}`);
          const body = await response.json();
          setLoadingStockDataSpinner("visually-hidden")
          setStocksData(body);
        }
        catch (err) {
          console.log(err.message)
        }
    }

    function extractStockDataKeys(){
        if(tickers.length === 0 || Object.keys(stocksData).length === 0 || stocksData[tickers[0]] == null){
            return []
        }

        return Object.keys(stocksData[tickers[0]]);
    }

    function formatStockData(key, value){
        var trophy = ""
        const trophyStyle = {fontSize: "14px", color: "#dba617"}

        if(value != undefined && value.trim() === '-'){
            return <Badge bg="danger">{value}</Badge>;
        } else if((key.includes("Debt/Eq")
                    || key.includes("PEG")
                    || key.includes("P/E"))
                        && isSmallestValue(key, value)){
            trophy = <Icon.TrophyFill style={trophyStyle} />
        } else if((key.includes("Dividend")
                    || key.includes("Operating Margin")
                    || key.includes("EPS")
                    || key.includes("ROI")
                    || key.includes("Insider Ownership"))
                        && isBiggestValue(key, value)) {
            trophy = <Icon.TrophyFill style={trophyStyle} />
        }

        return <Badge bg="-">{value} {trophy}</Badge>;
    }

    function isSmallestValue(key, value){
        for(const ticker of tickers){
            if(parseFloat(stocksData[ticker][key]) < parseFloat(value)){
                return false;
            }
        }

        return true;
    }
    function isBiggestValue(key, value){
        for(const ticker of tickers){
            if(parseFloat(stocksData[ticker][key]) > parseFloat(value)){
                return false;
            }
        }

        return true;
    }

  return (
      <>
        <InputGroup size="lg" style={{width: '70%', marginLeft: '15%'}}>
            <InputGroup.Text id="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                 <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>
           </InputGroup.Text>
            <AsyncTypeahead
              defaultSelected={options}
              style={{border: '0px'}}
              size="lg"
              clearButton
              multiple
              filterBy={filterBy}
              id="search-stock"
              isLoading={isLoading}
              labelKey="name"
              minLength={1}
              onSearch={handleSearch}
              onChange={setSearchQuery}
              options={options}
              placeholder="Search Symbol or Company name, eg. AAPL or Apple"
              renderMenuItemChildren={(option) => (
                <span>{option.ticker} - {option.name}</span>
              )}/>
              <Button
                  onClick={() => {
                    handleCompare();
                  }}
                  variant="outline-secondary">
                  Compare
               </Button>
        </InputGroup>
        <div className="text-center" style={{padding: '30px'}}>
            <Loading visibility={loadingStockDataSpinner} />
        </div>

        <Table striped bordered hover variant="dark" responsive="sm" size="sm" className={"visually-"+(Object.keys(stocksData).length === 0? "hidden":"visible")}>
          <thead>
            <tr>
                <th><Icon.PlusSlashMinus /></th>
                {tickers.map(ticker => (
                    <th key={"head_"+ticker}>
                    <a href={"/?search="+ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{ticker}</a>
                    </th>
                ))}
            </tr>
          </thead>
          <tbody>
                {extractStockDataKeys().map(key => (
                    <tr key={"row_"+key}>
                       <td><small><b>{key}</b></small></td>
                       {tickers.map(ticker => (
                           <td key={"col_"+ticker+"_"+key}><small>{formatStockData(key, stocksData[ticker][key])}</small></td>
                       ))}
                    </tr>
                ))}
          </tbody>
        </Table>
      </>
  )
}