import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";

const ExportFullPagePDF = ({ fileName }) => {
  const exportPDF = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to top

    setTimeout(() => {
      const element = document.body;

      html2canvas(element, {
        scale: 2,
        useCORS: true,
        windowWidth: document.documentElement.scrollWidth,
        windowHeight: document.documentElement.scrollHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.5); //0.5 => 50% quality

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // A4 dimensions in mm
        const pdfWidth = 210;
        const pdfHeight = (imgHeight / imgWidth) * pdfWidth; // Dynamically scale height

        const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);

        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, null, "FAST");

        const today = new Date();
        const shortDate = today.toISOString().split("T")[0];
        pdf.save(`${fileName}-${shortDate}-SigmaTraderNet.pdf`);
      });
    }, 1000);
  };

  return (
    <Button variant="btn btn-outline-secondary" size="sm" onClick={exportPDF}>
      <Icon.BoxArrowDown /> Export as PDF
    </Button>
  );
};

export default ExportFullPagePDF;



//import React from "react";
//import { jsPDF } from "jspdf";
//import html2canvas from "html2canvas";
//import Button from 'react-bootstrap/Button';
//import * as Icon from 'react-bootstrap-icons';
//
//const ExportFullPagePDF = ({fileName}) => {
//  const exportPDF = () => {
//    const element = document.body; // Capture entire page
//
//    html2canvas(element, {
//      scale: 2, // Higher scale for better resolution
//      useCORS: true, // Handle images from external sources
//      windowWidth: document.documentElement.scrollWidth,
//      windowHeight: document.documentElement.scrollHeight,
//    }).then((canvas) => {
//      const imgData = canvas.toDataURL("image/jpeg", 0.5); // 0.7 = 70% quality
//
//      // A4 dimensions in pixels at 96 DPI (Standard resolution)
//      const pdf = new jsPDF("p", "mm", "a4");
//      const pdfWidth = 210; // A4 width in mm
//      const pdfHeight = 297; // A4 height in mm
//      const imgWidth = pdfWidth;
//      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale proportionally
//
//      let heightLeft = imgHeight;
//      let position = 0;
//
//      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//
//      while (heightLeft > pdfHeight) {
//        position -= pdfHeight; // Move to next page
//        pdf.addPage();
//        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//        heightLeft -= pdfHeight;
//      }
//
//      const today = new Date();
//      const shortDate = today.toISOString().split("T")[0];
//      pdf.save(`${fileName}-${shortDate}.pdf`);
//    });
//  };
//
//  return (
//    <Button variant="btn btn-outline-primary" size="sm" onClick={exportPDF}><Icon.BoxArrowDown /> Export as PDF</Button>
//  );
//};
//
//export default ExportFullPagePDF;
